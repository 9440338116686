<template>
  <div>
    <!-- 物业报表 -->
    <div class="content-wrap">
      <paged-table
        :data="realList"
        :total="realTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校" width="180">
        </el-table-column>
        <el-table-column sortable="custom" prop="extend.userName" show-overflow-tooltip label="发布者"></el-table-column>
        <el-table-column sortable="custom" prop="phone" show-overflow-tooltip label="联系电话" width="120"></el-table-column>
        <el-table-column sortable="custom" prop="room" show-overflow-tooltip label="宿舍号"></el-table-column>
        <el-table-column sortable="custom" prop="content" show-overflow-tooltip label="内容" width="160"></el-table-column>
        <el-table-column sortable="custom" prop="extend.handlerName" show-overflow-tooltip label="修理者" ></el-table-column>
        <el-table-column sortable="custom" prop="statusName" show-overflow-tooltip label="状态">
        </el-table-column>
        <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="时间" width="180"></el-table-column>
        <el-table-column align="right" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" v-if="OA.includes('network:realService:del')" ></el-button>
            <el-button v-if="scope.row.status==1 && OA.includes('network:realService:send')" size="mini" type="warning" @click="designate(scope.row)" icon="el-icon-right" title="派单"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>
    <!-- 详情 -->
    <el-dialog
      title="物业报修详情"
      :visible.sync="bVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        ref="detailsFileds"
        :model="details"
        size="small"
      >
        <el-form-item label="学校：" prop="name">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="发布者：" prop="code">
          <span v-text="details.extend.userName"></span>
        </el-form-item>
        <el-form-item label="联系电话：" prop="code">
          <span v-text="details.phone"></span>
        </el-form-item>
        <el-form-item label="宿舍号：" prop="code">
          <span v-text="details.room"></span>
        </el-form-item>
        <el-form-item label="内容：" prop="code">
          <span v-text="details.content"></span>
        </el-form-item>
        <el-form-item label="修理者：" prop="code">
          <span v-text="details.extend.handlerName"></span>
        </el-form-item>
        <el-form-item label="状态：" prop="code">
          <span v-text="details.statusName"></span>
        </el-form-item>
        <el-form-item label="时间：" prop="code">
          <span v-text="details.addTime"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 派单 -->
    <el-dialog
      title="派单"
      :visible.sync="orderVisible"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="80px"
        ref="orderUserFileds"
        :model="orderUser"
        size="small"
      >
        <el-form-item label="维修人员" prop="handleId">
          <el-select clearable filterable v-model="orderUser.id">
            <el-option v-for="item in statusOptions" :label="item.extend.userName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="orderVisible = false">取消</el-button>
          <el-button size="small" type="primary" :loading="bLoading" @click="commit">立即提交</el-button>
        </div>
    </el-dialog>

  </div>
</template>


<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'realService',
  components: {
    PagedTable
  },
  mixins: [pageMixin],
  data() {
    return {
      statusOptions:[],
      queryParams:{
        type: 2
      },
      orderVisible: false,
      details: {
        extend:{
          schoolName:"",
          handlerName:"",
          userName:""
        },
        phone:"",
        content:"",
        room:"",
        statusName:"",
        addTime:""
      },
      orderUser:{
        id:"",
      },
      // 报修单id
      id:null,

      pageApi: "repairList",
      deleteApi: "repairDelete",
      // insertApi: "commonRoleAdd",
      // editorApi: "commonRoleEdit",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("realService", ['realList','realTotal']),
  },
  methods: {
    ...mapActions("realService",['repairList','repairEdit','repairLoad','repairDelete','repairAssign','getAppRoleUsers']),
    // 打开派单功能弹窗
    async designate(row) {
      console.log(row)
      console.log(row.schoolId)
      this.orderVisible = true
      this.id =  row.id
      let obj = {
        schoolId: row.schoolId,
        appRoleId: 1
      }
      const res = await this.getAppRoleUsers(obj)
      console.log(res)
      this.statusOptions = res
    },
    changeUser() {
    },
    async commit() {
      this.orderVisible = false
      let obj = {
        handlerId: this.orderUser.id,
        id: this.id
      }
      const res = await this.repairAssign(obj)
      console.log(res)
      this.$message.success('分配成功')
      this.refresh()
    }
  },
  async mounted() {
    // if(this.getPageParams(this.$options.name))
    //   mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>